import {
    FlowboxCheckoutData,
    FlowboxCheckoutInitializerConfig,
    FlowboxInitializerConfig,
} from '@app-services/flowbox/flowbox.types'
import { loadScript } from '@app-lib/base.lib'

declare let flowbox: any

declare global {
    interface Window {
        flowbox: any
        flowboxInitializers: FlowboxInitializerConfig[]
        flowboxCheckout: FlowboxCheckoutInitializerConfig
    }
}

export class FlowboxInitializerHandler {
    constructor(checkoutData?: FlowboxCheckoutData) {
        this.initFlowbox()

        if (checkoutData) {
            this.initiateCheckoutFlowbox(checkoutData)
        }
    }

    private initFlowbox(): void {
        const idleCallback = window.requestIdleCallback || setTimeout

        idleCallback(() => {
            this.initiate()

            if (window.flowboxInitializers) {
                window.flowboxInitializers.forEach(initializerData => {
                    let parsedTags: string[] = []

                    if (typeof initializerData.tags === 'string') {
                        parsedTags = JSON.parse(initializerData.tags)
                    } else if (Array.isArray(initializerData.tags)) {
                        parsedTags = initializerData.tags
                    }

                    const formattedTags: string = parsedTags.join(',')

                    flowbox('init', {
                        ...initializerData,
                        'tags': formattedTags,
                    })
                })
            }
        })
    }

    public initiate(): void {
        const d = document
        const id = 'flowbox-js-embed'

        if (! (window as any).flowbox) {
            const f = function () {
                (f as any).q.push(arguments)
            };
            (f as any).q = [];
            (window as any).flowbox = f
        }

        if (d.getElementById(id)) {
            return
        }

        const s = d.createElement('script')
        const fjs = d.scripts[d.scripts.length - 1]
        s.id = id
        s.async = true
        s.src = 'https://connect.getflowbox.com/flowbox.js'
        fjs.parentNode!.insertBefore(s, fjs)
    }

    public initiateCheckoutFlowbox({ key, products, orderId }: FlowboxCheckoutData): void {
        loadScript('//connect.getflowbox.com/bzfy-checkout.js')
            .then(() => {
                window.flowboxCheckout.checkout({
                    apiKey: key,
                    products,
                    orderId,
                })
            })
            .catch(() => {
                // ..
            })
    }
}
