// ------------------------------------------------------------------------------
//      Bootstrap
// ------------------------------------------------------------------------------
import '@app-bootstrap/convert-to-local-urls.bootstrap'
import '@app-bootstrap/credits.bootstrap'
import '@app-bootstrap/app.bootstrap'
import '@app-bootstrap/focus-within.bootstrap'
import '@app-bootstrap/bugsnag.bootstrap'
import { RECAPTCHA_INVISIBLE_SITEKEY, recaptchaReady } from '@app-bootstrap/recaptcha.bootstrap'

// ------------------------------------------------------------------------------
//      Services
// ------------------------------------------------------------------------------
import '@app-services/site-header/site-header.service'
import '@app-services/mobile-header/mobile-header.service'
import '@app-services/mobile-menu/mobile-menu.service'
import '@app-services/search/search.service'
import '@app-services/browser-support/browser-support.service'
import '@app-services/favorites/favorites.service'
import { notificationWrapper, shoppingList } from '@app-services/shopping-list/shopping-list.service'
import { Tracker } from '@app-services/tracker/tracker.service'
import { BuyableAction } from '@app-services/shopping-list/shopping-list.types'

// ------------------------------------------------------------------------------
//      Components
// ------------------------------------------------------------------------------
import { UspBar } from '@app-components/usp-bar/usp-bar.component'
import { DatePicker } from '@app-components/date-picker/date-picker.component'
import { createCountdown } from '@app-components/countdown/countdown.factory'

// ------------------------------------------------------------------------------
//      Vendor deps
// ------------------------------------------------------------------------------
import { filter, pluck } from 'rxjs/operators'
import { hide, queryAll, show, touchElement } from 'lambda-dom'
import { always, construct, isNil, tryCatch } from 'ramda'
import EventDelegation from '@jjwesterkamp/event-delegation'

// ------------------------------------------------------------------------------
//      AB-tests
// ------------------------------------------------------------------------------
import { initABTests } from './ab-tests'

// ------------------------------------------------------------------------------
//      Injections
// ------------------------------------------------------------------------------
import { ajaxFormFactoryInjector, swiperFactoryInjector } from '@app-injections'
import { AjaxFormHandler, openWindow } from '@app-services'
import { LoginPage } from './login/login.page'
import { AddressLookupFactory  } from '@app-factories'
import { FlowboxService } from '@app-services/flowbox/flowbox.service'
import { FlowboxInitializerHandler } from '@app-services/flowbox/flowbox-initializer'
import { initZendeskChatWidget, openChat } from './vendor/zendesk-chat'
import { initSVGSprite } from '@app-services/svg-sprite/svg-sprite'

console.log('test')

// ------------------------------------------------------------------------------
//      SVG Sprite
// ------------------------------------------------------------------------------
const svgSpriteContainer = document.querySelector<HTMLElement>('[data-svg-sprite-container]')

if (svgSpriteContainer) {
    initSVGSprite(svgSpriteContainer)
}

// ------------------------------------------------------------------------------
//      Shopping list mutations
// ------------------------------------------------------------------------------
queryAll<HTMLElement>('.usp-bar').map(construct(UspBar))
queryAll<HTMLElement>('.countdown').map((element) => tryCatch(createCountdown, always(null))(element))

// -------------------------------------------------------------------------
//      Initialize swiper components
// -------------------------------------------------------------------------
document.addEventListener('DOMContentLoaded', () => {
    swiperFactoryInjector.initialize()
})

// -------------------------------------------------------------------------
// On any change of the shopping list, update Google Tag Manager with the
// new information.

shoppingList.mutations$
    .pipe(
        pluck('report'),
        filter((report) => report !== null && report.realQuantityDelta() !== 0),
    )
    .subscribe(Tracker.handleShoppingListMutation)

// -------------------------------------------------------------------------
// Take all mutations that were triggered by an 'add' request-type, and show
// an added-to-cart notification using the mutation-report response.

shoppingList.mutations$
    .pipe(
        filter(({ requestType, report }) => requestType === BuyableAction.ADD && report !== null),
    )
    .subscribe((mutation) => notificationWrapper.handleReport(mutation.report!))

// -------------------------------------------------------------------------
// Footer link lists: these are collapsible for small viewports, and will be
// collapsed by default. The styles matching the active state are managed
// with CSS:
EventDelegation
    .global()
    .events('click')
    .select('.site-footer--link-list-container .site-footer--heading')
    .listen((e) => {
        (e.delegator.parentNode as HTMLElement).classList.toggle('active')
    })

// ------------------------------------------------------------------------------
//      Date pickers (currently only used for gift-card combination)
// ------------------------------------------------------------------------------

const tomorrow = () => {
    const today = new Date()
    return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1,
    )
}

queryAll<HTMLElement>('[data-flat-datepicker]').map((input) => new DatePicker(input, {
    minDate: tomorrow(),
}))

// ------------------------------------------------------------------------------
//      Flowbox
// ------------------------------------------------------------------------------
const flowboxElements = document.querySelectorAll<HTMLElement>('[data-config-flowbox]')
const checkoutFlowbox = document.querySelector<HTMLElement>('[data-config-checkout-flowbox]')

if (checkoutFlowbox) {
    const checkoutData = JSON.parse(checkoutFlowbox.dataset.configCheckoutFlowbox!)
    new FlowboxInitializerHandler(checkoutData)
}

flowboxElements.forEach((element: HTMLElement) => {
    new FlowboxInitializerHandler()

    const dataConfigFlowbox = element.getAttribute('data-config-flowbox')!

    new FlowboxService(JSON.parse(dataConfigFlowbox))
})

// ------------------------------------------------------------------------------
//      Request password reset form
// ------------------------------------------------------------------------------
new LoginPage(ajaxFormFactoryInjector)

// ------------------------------------------------------------------------------
//      Recaptcha
// ------------------------------------------------------------------------------
recaptchaReady.then(() => {
    const newsLetterForm = document.querySelector<HTMLFormElement>('#site-footer-newsletter-form')
    const newsLetterSuccessContainer = document.querySelector<HTMLElement>(
        '#site-footer-newsletter-form-success-container',
    )

    if (newsLetterForm && newsLetterSuccessContainer) {
        const ajaxFormHandler: AjaxFormHandler = ajaxFormFactoryInjector.createInstance(
            newsLetterForm,
            'site-footer-newsletter-form-recaptcha-container',
        )

        newsLetterForm?.addEventListener('response', (event: CustomEvent) => {
            if (event.detail.success) {
                newsLetterSuccessContainer.innerHTML = event.detail.message
                hide(newsLetterForm)
                show(newsLetterSuccessContainer)
            }
        })

        newsLetterForm.addEventListener('error', (error: CustomEvent) => {
            Object.keys(error.detail.body).forEach((key: string) => {
                ajaxFormHandler.updateErrorMessage(key, error.detail.body[key])
            })
        })
    }

    function setupRecaptcha(selector: string) {
        touchElement(selector, (container) => {
            const form = container.closest('form')

            if (isNil(form)) {
                return null
            }

            const widget = grecaptcha.render(container, {
                size: 'invisible',
                sitekey: RECAPTCHA_INVISIBLE_SITEKEY,
                callback: () => form.submit(),
            })

            form.addEventListener('submit', (e: CustomEvent) => {
                e.preventDefault()
                grecaptcha.execute(widget, 0)
            })

            return widget
        })
    }

    setupRecaptcha('#contact-form-recaptcha')
    setupRecaptcha('#register-form-recaptcha')
    setupRecaptcha('#newsletter-landing-form-recaptcha')
})

// ------------------------------------------------------------------------------
//      Address lookup
// ------------------------------------------------------------------------------
new AddressLookupFactory().initialize()

// ------------------------------------------------------------------------------
//      GTM push events
// ------------------------------------------------------------------------------
document.querySelectorAll('[data-gtm-push-ecommerce]').forEach(() => {
    Tracker.pushGTMInitialForEcommerce()
})

document.querySelectorAll('[data-gtm-push-data]').forEach((element: HTMLElement) => {
    const data = JSON.parse(element.getAttribute('data-gtm-push-data')!)

    Tracker.pushGTMData(data)
})

document.querySelectorAll('[data-debug-gtm-push-data]').forEach((element: HTMLElement) => {
    const data = JSON.parse(element.getAttribute('data-debug-gtm-push-data')!)

    const preCheckoutBody = JSON.stringify({
        type: 'checkout-datalayer-push-pre',
        message: 'About to push purchase event tracking data to the dataLayer stack',
        extra: JSON.stringify({
            json: data,
        }),
    })

    Tracker.debugCheckout('/debugging/xhr-debug', preCheckoutBody)

    try {
        Tracker.pushGTMData(data)
    } catch (err) {
        console.error('Failed to track purchase: ', err.message)

        const errorData = {
            type: 'checkout-datalayer-push-error',
            message: err.message,
            extra: JSON.stringify({
                error: {
                    name: err.name,
                    message: err.message,
                },
                json: data,
            }),
        }

        Tracker.debugCheckout('/debugging/xhr-error', JSON.stringify(errorData))
    }

    const postCheckoutBody = JSON.stringify({
        type: 'checkout-datalayer-push-post',
        message: 'Done pushing purchase event tracking data to the dataLayer stack',
        extra: null,
    })

    Tracker.debugCheckout('/debugging/xhr-debug', postCheckoutBody)
})

// ------------------------------------------------------------------------------
//      SpotlerActivate push events
// ------------------------------------------------------------------------------
document.querySelectorAll('[data-spotler-push-data]').forEach((element: HTMLElement) => {
    const data = JSON.parse(element.getAttribute('data-spotler-push-data')!)

    Tracker.pushSpotlerData(data)
})

// ------------------------------------------------------------------------------
//      Share via Social Media Popup
// ------------------------------------------------------------------------------
document.querySelectorAll('[data-trigger="popup:social-media-share"]').forEach((element: HTMLAnchorElement) => {
    element.addEventListener('click', (event) => {
        event.preventDefault()
        openWindow(element.href, element.title, 600, 600)
    })
})

// ------------------------------------------------------------------------------
//      Chat Widget (Zendesk)
// ------------------------------------------------------------------------------
initZendeskChatWidget()

document.querySelectorAll('[data-trigger="chat:show"]').forEach((element: HTMLAnchorElement) => {
    element.addEventListener('click', openChat)
})

// ------------------------------------------------------------------------------
//      AB-tests
// ------------------------------------------------------------------------------
initABTests()
